import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-75de9cc8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "10",
  height: "10",
  viewBox: "0 0 10 10",
  class: "svg"
}
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("circle", {
      id: "Ellipse_20",
      "data-name": "Ellipse 20",
      cx: "5",
      cy: "5",
      r: "5",
      fill: _ctx.fillOrStrokeColor
    }, null, 8, _hoisted_2)
  ]))
}