import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6e12e17c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-label"]
const _hoisted_2 = { class: "wrap" }
const _hoisted_3 = { class: "mp-div-1" }
const _hoisted_4 = { class: "mp-div-1" }
const _hoisted_5 = { class: "mp-div-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_m_big_eye = _resolveComponent("m-big-eye")!
  const _component_m_small = _resolveComponent("m-small")!
  const _component_m_cockroach = _resolveComponent("m-cockroach")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["m-prediction-root", _ctx.preColor]),
    "data-label": _ctx.preLabel
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (!_ctx.hasNoData)
          ? (_openBlock(), _createBlock(_component_m_big_eye, {
              key: 0,
              color: _ctx.bigeyeColor
            }, null, 8, ["color"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        (!_ctx.hasSmall)
          ? (_openBlock(), _createBlock(_component_m_small, {
              key: 0,
              color: _ctx.smallColor
            }, null, 8, ["color"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_5, [
        (!_ctx.hasCockroach)
          ? (_openBlock(), _createBlock(_component_m_cockroach, {
              key: 0,
              color: _ctx.cocroachColor
            }, null, 8, ["color"]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 10, _hoisted_1))
}