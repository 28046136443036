import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bfd57894"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "10.828",
  height: "10.828",
  viewBox: "0 0 10.828 10.828",
  class: "svg"
}
const _hoisted_2 = ["stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("line", {
      id: "Line_6",
      "data-name": "Line 6",
      x1: "8",
      y2: "8",
      transform: "translate(1.414 1.414)",
      fill: "none",
      stroke: _ctx.fillOrStrokeColor,
      "stroke-linecap": "round",
      "stroke-width": "2"
    }, null, 8, _hoisted_2)
  ]))
}